export default function DrawerRoutes(authorizations, user) {
    return [
        { header: 'Total OCS' },
        // { header: "Total App", icon: "mdi mdi-dots-horizontal" },
        {
            id: 'home',
            icon: 'home',
            title: 'Home',
            to: '/home',
            active: authorizations.includes('/Home'),
        },
        {
            id: 'dashboard',
            icon: 'grid',
            title: 'Dashboard',
            to: '/dashboard',
            active: authorizations.includes('/Dashboards'),
        },
        {
            id: 'customers',
            icon: 'user',
            title: 'Customer/Companies',
            active: [
                '/Profiles-GetAll',
                '/CustomerDocumentTypes-GetAll',
                '/CustomerTypes-GetAll',
                '/Profiles-GetAll-Active',
                '/Profiles-GetAll-Inactive',
            ].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-account-multiple',
                    title: 'All Customers',
                    to: '/customers',
                    active: authorizations.includes('/Profiles-GetAll'),
                },
                {
                    icon: 'mdi mdi-account-multiple',
                    title: 'All Companies',
                    to: '/customers/companies',
                    active: authorizations.includes('/Profiles-GetAll'),
                },
                // {
                //     icon: 'mdi mdi-account-multiple',
                //     title: 'Active Customers',
                //     to: '/customers?status=active',
                //     active: authorizations.includes('/Profiles-GetAll-Active'),
                // },
                // {
                //     icon: 'mdi mdi-account-multiple',
                //     title: 'Inactive Customers',
                //     to: '/customers?status=inactive',
                //     active: authorizations.includes('/Profiles-GetAll-Inactive'),
                // },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Types',
                    to: '/customers/reasons-types',
                    active: authorizations.includes('/CustomerTypes-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Documents',
                    to: '/customers/documents-types',
                    active: authorizations.includes('/CustomerDocumentTypes-GetAll'),
                },
            ],
        },
        {
            id: 'loan-applications',
            icon: 'users',
            title: 'Loan Applications',
            i18n: 'Loan Applications',
            name: '/applications/',
            active: [
                '/LoanApplications-GetAll',
                '/LoanApplications-Add',
                '/LoanApplications-Additional-Details',
                '/LoanApplications-Membercheck',
                '/LoanApplications-Screening',
                '/LoanApplications-Job-Verification',
                '/LoanApplications-Verify-Information',
                '/LoanApplications-Approval',
                '/LoanApplications-Signature',
                '/LoanApplications-Transfer-Of-Founds',
                '/LoanApplications-Details',
                '/LoanApplications-Completed',
                '/LoanApplications-Closed',
                '/LoanApplications-Assigned',
                '/LoanApplications-All',
            ].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'All',
                    to: '/applications',
                    active: authorizations.includes('/LoanApplications-GetAll'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Assigned Me',
                    to: '/applications?slug=assigned',
                    active: authorizations.includes('/LoanApplications-Assigned'),
                },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Details',
                //     to: '/applications?slug=fill-additional-details',
                //     active: authorizations.includes('/LoanApplications-Additional-Details'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Compliance',
                //     to: '/applications?slug=membercheck',
                //     active: authorizations.includes('/LoanApplications-Membercheck'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Screening',
                //     to: '/applications?slug=screening',
                //     active: authorizations.includes('/LoanApplications-Screening'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Job Verification',
                //     to: '/applications?slug=job-verification',
                //     active: authorizations.includes('/LoanApplications-Job-Verification'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Approval',
                //     to: '/applications?slug=verify-all-information',
                //     active: authorizations.includes('/LoanApplications-Verify-Information'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Approval',
                //     to: '/applications?slug=approval-lvl-1.2',
                //     active: authorizations.includes('/LoanApplications-Approval'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Signature',
                //     to: '/applications?slug=signature',
                //     active: authorizations.includes('/LoanApplications-Signature'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Transfer of Funds',
                //     to: '/applications?slug=transfer-of-funds',
                //     active: authorizations.includes('/LoanApplications-Transfer-Of-Founds'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Closed',
                //     to: '/applications?slug=closed',
                //     active: authorizations.includes('/LoanApplications-Closed'),
                // },
                // {
                //     icon: 'mdi mdi-file-tree',
                //     title: 'Completed',
                //     to: '/applications?slug=completed',
                //     active: authorizations.includes('/LoanApplications-Completed'),
                // },
            ],
        },
        {
            id: 'loans',
            icon: 'dollar-sign',
            title: 'Loans',
            i18n: 'Loans',
            name: '/loans/',
            active: [
                '/LoanApplications-All',
                '/LoanApplications-Approved',
                '/LoanApplications-Active',
                '/LoanApplications-Pending-Approved',
                '/LoanApplications-Closed',
            ].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'All',
                    to: '/loans/all',
                    active: authorizations.includes('/LoanApplications-All'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Active',
                    to: '/loans/active',
                    active: authorizations.includes('/LoanApplications-Active'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Approved',
                    to: '/loans/approved',
                    active: authorizations.includes('/LoanApplications-Approved'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Pending Approval',
                    to: '/loans/pending-approval',
                    active: authorizations.includes('/LoanApplications-Pending-Approved'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Closed',
                    to: '/loans/closed',
                    active: authorizations.includes('/LoanApplications-Closed'),
                },
            ],
        },
        {
            id: 'products',
            icon: 'package',
            title: 'Products',
            name: '/products/',
            active: ['/LoanTypes', '/Rules'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-calculator',
                    title: 'Rules',
                    to: '/products/rules',
                    active: authorizations.includes('/Rules'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Types',
                    to: '/products/types',
                    active: authorizations.includes('/LoanTypes'),
                },
            ],
        },
        {
            id: 'partners',
            icon: 'user-plus',
            title: 'Partners',
            name: '/loans-affiliates/',
            active: ['/Partners-GetList'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Index',
                    to: '/loans-affiliates',
                    active: authorizations.includes('/Partners-GetList'),
                },
                {
                    icon: 'mdi mdi-calculator',
                    title: 'Calculator',
                    to: '/loans-affiliates/calculator',
                    active: authorizations.includes('/Partners-GetList') && user.Roles[0] != 'Admin',
                },
            ],
        },
        {
            id: 'verifications',
            icon: 'briefcase',
            title: 'Verifications',
            name: '/verifications/',
            active: ['/ScreeningVerification'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Screenings Requests',
                    to: '/verifications/screening-requests',
                    active: true,
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Screenings Verifications',
                    to: '/verifications/screening-verifications',
                    active: authorizations.includes('/ScreeningVerification'),
                },
                {
                    icon: 'mdi mdi-file-tree',
                    title: 'Jobs verifications',
                    to: '/verifications/job-verifications',
                    active: true,
                },
            ],
        },
        {
            id: 'general',
            icon: 'settings',
            title: 'General',
            i18n: 'General',
            name: '/general/',
            active: ['/Banks-GetAll', '/Countries-GetAll', '/Cities-GetAll', '/States-GetAll'].some((string) =>
                authorizations.includes(string),
            ),
            childern: [
                {
                    icon: 'mdi mdi-bank',
                    title: 'Banks',
                    to: '/general/banks',
                    active: authorizations.includes('/Banks-GetAll'),
                },
                {
                    icon: 'mdi mdi-map',
                    title: 'Countries',
                    to: '/general/countries',
                    active: authorizations.includes('/Countries-GetAll'),
                },
                {
                    icon: 'mdi mdi-city',
                    title: 'Cities',
                    to: '/general/cities',
                    active: authorizations.includes('/Cities-GetAll'),
                },
                {
                    icon: 'mdi mdi-map-marker-multiple',
                    title: 'States',
                    to: '/general/states',
                    active: authorizations.includes('/States-GetAll'),
                },
            ],
        },
        {
            id: 'authentication',
            icon: 'lock',
            title: 'Authentication',
            i18n: 'Authentication',
            name: '/authentication/',
            active: ['/Roles-GetAll', '/Users-GetAll'].some((string) => authorizations.includes(string)),
            childern: [
                {
                    icon: 'mdi mdi-account-multiple',
                    title: 'Users',
                    to: '/security/users',
                    active: authorizations.includes('/Users-GetAll'),
                },
                {
                    icon: 'mdi mdi-account-convert',
                    title: 'Roles',
                    to: '/security/roles',
                    active: authorizations.includes('/Roles-GetAll'),
                },
            ],
        },
    ];
}
